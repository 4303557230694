/**
 * Created by osirvent on 25/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaDossierCustomFieldsBox', {
        templateUrl: './components/tram/annexa-dossier-custom-fields-box/annexa-dossier-custom-fields-box.html',
        controller: ['Language', 'TramFactory', 'GlobalDataFactory', 'AnnexaFormlyFactory', 'DialogsFactory', 'CustomFieldFactory', '$state', '$filter', 
		function (Language, TramFactory, GlobalDataFactory, AnnexaFormlyFactory, DialogsFactory, CustomFieldFactory, $state, $filter) {
            var vm = this;
			vm.canChangeDocumentType = false;
            vm.languageColumn = Language.getActiveColumn();

 			this.$onInit = function() {
				if(!vm.boxTitle) {
                    vm.boxTitle = 'global.literals.customFields';
            	}
				if(!vm.emptyText) {
                    vm.emptyText = 'global.literals.noCustomFields';
            	}
           }
        }],
        bindings: {
            boxTitle: '@?',
            permissions: '=',
            content: '=',
            isEdit: '=',
            config: '=',
            origin: '@',
            emptyText: '@',
			newTitle: '@?'
        }
    })
	.component('annexaDossierCustomFieldsBoxRender', {
        templateUrl: './components/tram/annexa-dossier-custom-fields-box/annexa-dossier-custom-fields-box-render.html',
        require: {
            objectBoxComponent: '^^annexaObjectBox',
        },
        controller:['SecFactory', '$filter', 'DialogsFactory', '$rootScope', 'Language', 'AnnexaFormlyFactory', '$scope', '$state', 'TramFactory', 'GlobalDataFactory','globalModals', 'AnnexaModalFactory', 'SecFactory', 
        function(SecFactory, $filter, DialogsFactory, $rootScope, Language, AnnexaFormlyFactory, $scope, $state, TramFactory,GlobalDataFactory, globalModals, AnnexaModalFactory, SecFactory) {
    		var vm = this;
        	vm.languageColumn = Language.getActiveColumn();
			vm.updateCustomField =  function(cfId, value){
                if(cfId) {
                    var cf = $linq(vm.content).firstOrDefault(undefined, "x => x.customField && x.customField.id == " + cfId);
                    if(cf){
                        if(value || value == ''){
                            cf.value = value;
                        }
						if(cf.requiredString){
	                        cf.required = cf.requiredString == 'REQUIRED';
			                cf.requiredForEndDossier = cf.requiredString == 'REQUIRED_TO_END';
			                cf.hiddenField = cf.requiredString == 'HIDDEN_FIELD';
			                cf.noEditable = cf.requiredString == 'NO_EDITABLE';
						}
                    }
                }
			}

			this.$onInit = function() {
				
           }
        }],
        bindings: {
            content: '=',
            emptyText: '@'
        }
    })
	.component('annexaDossierCustomFieldsEditBox', {
        templateUrl: './components/tram/annexa-dossier-custom-fields-box/annexa-dossier-custom-fields-edit-box.html',
        controller: ['Language', 'TramFactory', 'GlobalDataFactory', 'AnnexaFormlyFactory', 'DialogsFactory', 'CustomFieldFactory', '$state', '$filter', 
		function (Language, TramFactory, GlobalDataFactory, AnnexaFormlyFactory, DialogsFactory, CustomFieldFactory, $state, $filter) {
            var vm = this;
			vm.canChangeDocumentType = false;
            vm.languageColumn = Language.getActiveColumn();

 			this.$onInit = function() {
				vm.dossier = ((vm.config && vm.config.dossier && vm.config.dossier.id)?vm.config.dossier:undefined);
				if(!vm.boxTitle) {
                    vm.boxTitle = 'global.literals.customFields';
            	}
				if(!vm.emptyText) {
                    vm.emptyText = 'global.literals.noCustomFields';
            	}
           }
        }],
        bindings: {
            boxTitle: '@?',
            permissions: '=',
            content: '=',
            isEdit: '=',
            config: '=',
            origin: '@',
            emptyText: '@',
			newTitle: '@?'
        }
    })
	.component('annexaDossierCustomFieldsEditBoxRender', {
        templateUrl: './components/tram/annexa-dossier-custom-fields-box/annexa-dossier-custom-fields-edit-box-render.html',
        require: {
            objectBoxComponent: '^^annexaObjectBox',
        },
        controller:['SecFactory', '$filter', 'DialogsFactory', '$rootScope', 'Language', 'AnnexaFormlyFactory', '$scope', '$state', 'TramFactory', 'GlobalDataFactory','globalModals', 'AnnexaModalFactory', 'SecFactory', 
        function(SecFactory, $filter, DialogsFactory, $rootScope, Language, AnnexaFormlyFactory, $scope, $state, TramFactory,GlobalDataFactory, globalModals, AnnexaModalFactory, SecFactory) {
    		var vm = this;
        	vm.languageColumn = Language.getActiveColumn();

			vm.updateCustomField =  function(field){
                if(field) {
					if(!field.dossier || !field.dossier.id){
						field.dossier = {id: ((vm.dossier && vm.dossier.id)?vm.dossier.id:undefined)}
					}
					TramFactory.updateDossierCustomField(field).then(function (data) {
                       if (field.customField.frontendType == 'SELECT') {
                            $scope.$broadcast('customFieldSelectSelected', {
                                customField: field.customField.id,
                                selectedValue: field.id
                            });
                        }  else if (field.customField.backendType == 'ADDRESS') {
							if(field.value){
								var aux = angular.fromJson(field.value);
								if(aux && aux.value){
									field.valueFromJSON = aux.value;
								}else {
									field.valueFromJSON = '';
								}
							}else{
								field.valueFromJSON = '';
							}
						}
                    }).catch(function (error) {
						DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
                        console.error(error);
                    });
                }
			}

			this.$onInit = function() {
				
           }
        }],
        bindings: {
            content: '=',
            emptyText: '@',
            isEdit: '=',
            dossier: '='
        }
    }) 