angular
    .module('annexaApp')
    .component('annexaBoxDossierAudit',{
        templateUrl: './components/tram/annexa-box-dossier-audit/annexa-box-dossier-audit.html',
        controller:['Language', '$rootScope', 'AnnexaObjectBoxFactory', 'GlobalDataFactory', '$filter', '$scope', '$state', 
        function(Language, $rootScope, AnnexaObjectBoxFactory, GlobalDataFactory, $filter, $scope, $state) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            
        	this.$onInit = function () {
            	if(!vm.boxTitle) {
                    vm.boxTitle = 'global.archive.auditBoxTitle';
                }
            	vm.emptyText = 'global.archive.auditBoxEmpty';
            	if(vm.content && vm.content.length > 0){
            		_.forEach(vm.content, function(audit){
            			var auditAction = $linq(GlobalDataFactory.auditActions).firstOrDefault({language1:audit.action, language2:audit.action,languge3:audit.action},"x=> x.id == '"+audit.action+"'");
            			audit.actionLiteral = $filter('translate')(auditAction[vm.languageColumn]);
            			audit.actionDate = ((audit.actionDate)?$filter('date')(new Date(audit.actionDate), 'dd/MM/yyyy HH:mm'):'');
            		});
            	}
            }
        }],
        bindings: {
            boxTitle: '@?',
            content: '=',
            show:'='
        }
    })