/**
 * Created by osirvent on 16/05/2017.
 */
angular
    .module('annexaApp')
    .component('annexaBudgetItems', {
        templateUrl: './components/tram/annexa-budget-items/annexa-budget-items.html',
        controller: ['TramFactory', 'RestService', 'Language', '$rootScope', '$filter', '$scope', 'DialogsFactory', '$q', function(TramFactory, RestService, Language, $rootScope,$filter, $scope, DialogsFactory, $q) {
            var vm = this;
            vm.showproject = false;
            $scope.$on('modifyBudgetItemsAccountingReferenceSelected', function(event, args) {
                vm.data.budgets.length = 0;
				if(args.budgetItems && args.budgetItems.length > 0) {
                	vm.data.budgetItems.length = 0;
                	var amount = 0;
                	var idAux = 1;
                	var areGes = args.budgetItems[0].budget.areGes;
                	var accountInstance = undefined;
                	if(TramFactory && TramFactory.userAccountInstances){
                		accountInstance = $linq(TramFactory.userAccountInstances).firstOrDefault(undefined, "x => x.code == '"+args.instanceCode+"'");
						vm.data.accountInstance = accountInstance;
                	}else{
						vm.data.accountInstance = undefined;
					}
                	if(accountInstance && accountInstance.useManagementCenter){
	                	TramFactory.getBudgets(accountInstance.code, areGes).then(function (data) {
							vm.data.projects.length = 0;
				    		if(data && data.length > 0){
		          			   data = JSOG.decode(data);
							   if(accountInstance.withProject){
							   		var projectsAux = $linq(vm.data.allProjects).where(function(x){
										if(x && x.gastos && x.gastos.length > 0){
											var gastos = $linq(x.gastos).intersect(data,"(x,y) => x.code == y.code").toArray(); 
											if(gastos && gastos.length > 0){
												return true;
											}else{
												return false;
											}
										}else{
											return false;
										}
									}).toArray();
									_.forEach(projectsAux, function(item){
		          						if(item.expedientes && item.expedientes.length > 1){
											for(var i= 0; i<item.expedientes.length; i++){
												var itemAux = angular.copy(item);
												itemAux.code = itemAux.code +'-'+item.expedientes[i].numExpe;
												itemAux.descripcion = '('+item.code +') '+itemAux.descripcion +' - ('+item.expedientes[i].numExpe+') '+item.expedientes[i].descExpe;
												itemAux.expediente = item.expedientes[i];
												vm.data.projects.push(itemAux);
											}
										}else{
											var itemAux = angular.copy(item);
											itemAux.descripcion = '('+itemAux.code +') '+itemAux.descripcion;												
											vm.data.projects.push(itemAux);	
										}
		          			   		});
							   }	
		          			   _.forEach(data, function(item){
		          				   vm.data.budgets.push(item);
		          			   })
	              		   	}
                        	_.forEach(args.budgetItems, function(item){
		                		var itemAux = $linq(vm.data.budgets).firstOrDefault(undefined,"x => x.code == '"+item.budget.code+"'");
		                		vm.data.budgetItems.push({idAux:idAux++,  project:((item.project && item.project.code)?item.project.code:undefined), budget:item.budget.code, availableCredit:((item.project && item.project.code)?'':((itemAux && itemAux.availableCredit)?itemAux.availableCredit:0.00)), amount:((item.amount)?item.amount:0.00), amountAux:((item.amount)?item.amount:0.00), initialAmount:((item.amount)?item.amount:0.00), numLine:item.budget.numLine, reference:item.budget.reference, operationReference:item.budget.operationReference, areGes:item.budget.areGes});
		                		amount = amount + ((item.amount)?item.amount:0.00);
		                	});
		                	$rootScope.$broadcast('AnnexaChangeAmountBudget', {amount: amount});
		                	$rootScope.$broadcast('finishedAccountingReferenceSelected', { areGes: areGes });
							$rootScope.$broadcast('tableEditableDynamicItemsFinished', {});
            		   }).catch(function (error) {
	              		   if(error && error.data && error.data.message && error.data.message != 'Error call to PDI'){
	              			   DialogsFactory.error(error.data.message);
	             		   }else{
	             			  DialogsFactory.error($filter('translate')('global.operationsAndInvoices.budgetsError'));
	             		   }
	                   });
                	}else{
                		_.forEach(args.budgetItems, function(item){
                    		var itemAux = $linq(vm.data.budgets).firstOrDefault(undefined,"x => x.code == '"+item.budget.code+"'");
                    		vm.data.budgetItems.push({idAux:idAux++,  project:((item.project && item.project.code)?item.project.code:undefined),  budget:item.budget.code, availableCredit:((item.project && item.project.code)?'':((itemAux && itemAux.availableCredit)?itemAux.availableCredit:0.00)), amount:((item.amount)?item.amount:0.00), amountAux:((item.amount)?item.amount:0.00), initialAmount:((item.amount)?item.amount:0.00), numLine:item.budget.numLine, reference:item.budget.reference, operationReference:item.budget.operationReference, areGes:item.budget.areGes});
                    		amount = amount + ((item.amount)?item.amount:0.00);
                    	});
                    	$rootScope.$broadcast('AnnexaChangeAmountBudget', {amount: amount});
                    	$rootScope.$broadcast('finishedAccountingReferenceSelected', { areGes: areGes });
                	}
                }
            });
            
            $scope.$on('newAccountingInstanceSelected', function(event, args) {
                vm.data.budgets.length = 0;
	            vm.data.projects.length = 0;
				vm.data.allProjects.length = 0;
				if(args.item && args.item.code) {
					vm.data.accountInstance = args.item;
					var promises = [];
                	promises.push(TramFactory.getBudgets(args.item.code));
            	    if(args.item.withProject){
						vm.showproject = true;
						promises.push(TramFactory.getProjects(args.item.code));
					}else{
						vm.showproject = false;
					}
					$rootScope.loadingDiv = true;
					$q.all(promises).then(function(data) {
						$rootScope.loadingDiv = false;
						if(data && data.length > 0){
							if(data.length > 1){
								var budgetsAux = JSOG.decode(data[0]);
								var projectsAux = JSOG.decode(data[1]);
								if(budgetsAux && budgetsAux.length > 0){ 
									if(projectsAux && projectsAux.length > 0){
										vm.data.allProjects = projectsAux;
										projectsAux = $linq(projectsAux).where(function(x){
											if(x && x.gastos && x.gastos.length > 0){
												var gastos = $linq(x.gastos).intersect(budgetsAux,"(x,y) => x.code == y.code").toArray(); 
												if(gastos && gastos.length > 0){
													return true;
												}else{
													return false;
												}
											}else{
												return false;
											}
										}).toArray();
										_.forEach(projectsAux, function(item){
			          				   		if(item.expedientes && item.expedientes.length > 1){
												for(var i= 0; i<item.expedientes.length; i++){
													var itemAux = angular.copy(item);
													itemAux.code = itemAux.code +'-'+item.expedientes[i].numExpe;
													itemAux.descripcion = '('+item.code +') '+itemAux.descripcion +' - ('+item.expedientes[i].numExpe+') '+item.expedientes[i].descExpe;
													itemAux.expediente = item.expedientes[i];
													vm.data.projects.push(itemAux);
												}
											}else{
												var itemAux = angular.copy(item);
												itemAux.descripcion = '('+itemAux.code +') '+itemAux.descripcion;												
												vm.data.projects.push(itemAux);	
											}
			          			   		});
									}
		          			   		_.forEach(budgetsAux, function(item){
	    	      				   		vm.data.budgets.push(item);
	        	  			   		});							
								}
							}else{
								var budgetsAux = JSOG.decode(data[0]);
	          			   		_.forEach(budgetsAux, function(item){
	          				   		vm.data.budgets.push(item);
	          			   		});
							}
						}
						vm.data.budgetItems.length = 0;
	                	$rootScope.$broadcast('AnnexaChangeAmountBudget', {amount: 0});
              	   }).catch(function (error) {
						$rootScope.loadingDiv = false;
						if(error && error.data && error.data.message && error.data.message != 'Error call to PDI'){
              			   DialogsFactory.error(error.data.message);
             		   }else{
             			  DialogsFactory.error($filter('translate')('global.operationsAndInvoices.budgetsError'));
             		   }
              		   vm.data.budgets.length = 0;
              		   vm.data.budgetItems.length = 0;
              		   $rootScope.$broadcast('AnnexaChangeAmountBudget', {amount: 0});
                   });
                }else{
					vm.data.accountInstance = undefined;
				}
            });
            
            
            $scope.$on('newAreGesSelected', function(event, args) {
				vm.data.budgets.length = 0;
				 if(args.item && args.item.code && args.item.instanceCode) {
                	var accountInstance = undefined;
                	if(TramFactory && TramFactory.userAccountInstances){
                		accountInstance = $linq(TramFactory.userAccountInstances).firstOrDefault(undefined, "x => x.code == '"+args.item.instanceCode+"'");
						vm.data.accountInstance = accountInstance;
                	}else{
						vm.data.accountInstance = undefined;
					}
                	if(accountInstance && accountInstance.useManagementCenter){
						TramFactory.getBudgets(args.item.instanceCode, args.item.code).then(function (data) {
		            		vm.data.projects.length = 0;
               				if(data && data.length > 0){
		          			   data = JSOG.decode(data);
								if(accountInstance.withProject){
									var projectsAux = $linq(vm.data.allProjects).where(function(x){
										if(x && x.gastos && x.gastos.length > 0){
											var gastos = $linq(x.gastos).intersect(data,"(x,y) => x.code == y.code").toArray(); 
											if(gastos && gastos.length > 0){
												return true;
											}else{
												return false;
											}
										}else{
											return false;
										}
									}).toArray();
									_.forEach(projectsAux, function(item){
		          				   		if(item.expedientes && item.expedientes.length > 1){
											for(var i= 0; i<item.expedientes.length; i++){
												var itemAux = angular.copy(item);
												itemAux.code = itemAux.code +'-'+item.expedientes[i].numExpe;
												itemAux.descripcion = '('+item.code +') '+itemAux.descripcion +' - ('+item.expedientes[i].numExpe+') '+item.expedientes[i].descExpe;
												itemAux.expediente = item.expedientes[i];
												vm.data.projects.push(itemAux);
											}
										}else{
											var itemAux = angular.copy(item);
											itemAux.descripcion = '('+itemAux.code +') '+itemAux.descripcion;												
											vm.data.projects.push(itemAux);	
										}
		          			   		});
								}
		          			   _.forEach(data, function(item){
		          				   vm.data.budgets.push(item);
		          			   })
	              		   	}
		                	vm.data.budgetItems.length = 0;
		                	$rootScope.$broadcast('AnnexaChangeAmountBudget', {amount: 0});
	              	   }).catch(function (error) {
	              		   if(error && error.data && error.data.message && error.data.message != 'Error call to PDI'){
	              			   DialogsFactory.error(error.data.message);
	             		   }else{
	             			  DialogsFactory.error($filter('translate')('global.operationsAndInvoices.budgetsError'));
	             		   }
	              		   vm.data.budgets.length = 0;
	              		   vm.data.budgetItems.length = 0;
	              		   $rootScope.$broadcast('AnnexaChangeAmountBudget', {amount: 0});
	                   });
					}
                }
            });
            
            this.budgetItemsColumns = [
            	{ id: 'budget', style: 'width:50%;', name: 'global.operationsAndInvoices.budgetItem', required: true, type: 'select', idProperty: 'code', nameProperty: 'name', list: vm.data.budgets, 
            	print:
            		function(item){
            			if(item && item.budget && item.budget.name){
            				return item.budget.name;
            			}else if(item && item.budget && typeof item.budget === 'string'){
            				var budget = $linq(vm.data.budgets).firstOrDefault(undefined, "x=> x.code == '"+item.budget+"'");
            				if(budget){
            					return budget.name;
            				}else{
            					return item.budget;          					
            				}
            			}
            		},
	            changeFunction: 
            		function($data, item){
            			if($data && vm.data.budgets && vm.data.budgets.length > 0 && item){
            				var budget = $linq(vm.data.budgets).firstOrDefault(undefined, "x=> x.code == '"+$data+"'");
            				if(budget){
            					item.availableCredit = budget.availableCredit;
            				}else{
            					item.availableCredit = 0.00;
            				}
            			}else if(item){
            				item.availableCredit = 0.00;
            			}
            		}
            	},
            	{ id: 'availableCredit', style: 'width:17%;', name: 'global.operationsAndInvoices.availableCredit', type: 'label', min: function () { return 0; }, max: function () {}, required: true },
                { id: 'amount', style: 'width:17%', name: 'global.operationsAndInvoices.amount', type: 'decimal', step:0.01, min: function (item) { return ((item && item.initialAmount)?-item.initialAmount:0.00); }, 
            	max: 
                	function (item) {
	                	if(item && item.availableCredit){
	                		return item.availableCredit
	                	}
            		}
                ,
	            changeFunction: 
            		function($data, item){
            			var amount = 0;
	            		item.amountAux = $data;
            			var exit = false;
            			_.forEach(vm.data.budgetItems, function(itemAux){
            				amount = amount + itemAux.amountAux;
            			});
            			$rootScope.$broadcast('AnnexaChangeAmountBudget', { amount: amount});
            		}
            	,
            	required: true,
            	checkRequiredFunction: 
            		function($data, item){
            			if($data && $data > item.availableCredit){
            				return $filter('translate')('global.validation.required');
            			}
            		}
            	},
                { id: 'actions', style: 'width:16%', name: '', type: 'actions' }
            ];

			this.budgetItemsProjectColumns = [
            	{ id: 'project', style: 'width:20%;', name: 'global.operationsAndInvoices.project', required: false, type: 'select', idProperty: 'code', nameProperty: 'descripcion', list: vm.data.projects, changeDynamic:true,  
            	print:
            		function(item){
            			if(item && item.project && item.project.descripcion){
            				return item.project.descripcion;
            			}else if(item && item.project && typeof item.project === 'string'){
            				var project = $linq(vm.data.projects).firstOrDefault(undefined, "x=> x.code == '"+item.project+"'");
            				if(project){
            					return project.descripcion;
            				}else{
            					return item.project;          					
            				}
            			}
            		},
	           		changeFunction: 
            		function($data, item, dynamicList){
            			var changeBudget = false;
						if(dynamicList && item && item.idAux){
							var auxList = undefined;
							if(dynamicList['budget_'+item.idAux]){
								auxList = angular.copy(dynamicList['budget_'+item.idAux]);
								dynamicList['budget_'+item.idAux].length = 0;
							}else{
								dynamicList['budget_'+item.idAux] = [];
							}
							if($data){
								var proj = $linq(vm.data.projects).firstOrDefault(undefined, "x => x.code == '"+$data+"'");
								if(proj.gastos && proj.gastos.length > 0){
									var dlb = $linq(vm.data.budgets).intersect(proj.gastos,"(x,y) => x.code == y.code").toArray();
									dynamicList['budget_'+item.idAux] = ((dlb)?angular.copy(dlb):[]); 
								}
							}else{
								dynamicList['budget_'+item.idAux] = angular.copy(vm.data.budgets);
							}
							if(dynamicList['budget_'+item.idAux] && dynamicList['budget_'+item.idAux].length > 0){
								if(auxList && auxList.length > 0){
									auxList = $linq(auxList).intersect(dynamicList['budget_'+item.idAux],"(x,y) => x.code == y.code").toArray(); 
									if(auxList && auxList.length > 0){
										changeBudget = true;
									}
								}
							}
						}
						if(!changeBudget){
							item.budget = undefined;
						}
						if($data){
							item.availableCredit = ''; 
							item.projectAux = $data;
						}
            		}
            	},
				{ id: 'budget', style: 'width:30%;', name: 'global.operationsAndInvoices.budgetItem', required: true, type: 'select-dynamic', idProperty: 'code', nameProperty: 'name', list: vm.data.budgets,idItem: 'idAux', 
            	print:
            		function(item){
            			if(item && item.budget && item.budget.name){
            				return item.budget.name;
            			}else if(item && item.budget && typeof item.budget === 'string'){
            				var budget = $linq(vm.data.budgets).firstOrDefault(undefined, "x=> x.code == '"+item.budget+"'");
            				if(budget){
            					return budget.name;
            				}else{
            					return item.budget;          					
            				}
            			}
            		},
	            	changeFunction: 
            		function($data, item){
            			if($data && vm.data.budgets && vm.data.budgets.length > 0 && item){
            				var budget = $linq(vm.data.budgets).firstOrDefault(undefined, "x=> x.code == '"+$data+"'");
            				if(budget){
            					item.availableCredit = ((item.projectAux)?'':budget.availableCredit);
            				}else{
            					item.availableCredit = ((item.projectAux)?'':0.00);
            				}
            			}else if(item){
            				item.availableCredit = ((item.projectAux)?'':0.00);
            			}
            		},
					dynamicContent:
					function(item){
						if(item && item.project && item.project.gastos){
							var dlb = $linq(vm.data.budgets).intersect(item.project.gastos,"(x,y) => x.code == y.code").toArray();
							return ((dlb)?angular.copy(dlb):[]);  
						}else{
							return angular.copy(vm.data.budgets);
						}
					}
            	},
            	{ id: 'availableCredit', style: 'width:17%;', name: 'global.operationsAndInvoices.availableCredit', type: 'label', min: function () { return 0; }, max: function () {}, required: true },
                { id: 'amount', style: 'width:17%', name: 'global.operationsAndInvoices.amount', type: 'decimal', step:0.01, min: function (item) { return ((item && item.initialAmount)?-item.initialAmount:0.00); }, 
            	max: 
                	function (item) {
	                	if(item && item.availableCredit){
	                		return item.availableCredit
	                	}
            		}
                ,
	            changeFunction: 
            		function($data, item){
            			var amount = 0;
	            		item.amountAux = $data;
            			var exit = false;
            			_.forEach(vm.data.budgetItems, function(itemAux){
            				amount = amount + itemAux.amountAux;
            			});
            			$rootScope.$broadcast('AnnexaChangeAmountBudget', { amount: amount});
            		}
            	,
            	required: true,
            	checkRequiredFunction: 
            		function($data, item){
            			if($data && !item.projectAux && $data > item.availableCredit){
            				return $filter('translate')('global.validation.required');
            			}
            		}
            	},
                { id: 'actions', style: 'width:16%', name: '', type: 'actions' }
            ];
            
            this.addBudgetItem = function(){
            	var idAux = ((vm.data.budgetItems && vm.data.budgetItems.length > 0)?$linq(vm.data.budgetItems).max("x => x.idAux")+1:1);
            	if(!idAux){
            		idAux = 1;
            	}
            	return {idAux:idAux,  budget:{}, availableCredit:0.00, amount:0.00, amountAux:0.00, initialAmount:0.00};
            }
            
            this.updateBudgetItem = function(item, index){
            	var a=0;
            }
            
            this.removeBudgetItem = function(index){
            	vm.data.budgetItems.splice(index, 1);
            	var amount = 0;
    			_.forEach(vm.data.budgetItems, function(itemAux){
    				amount = amount + itemAux.amountAux;
    			});
    			$rootScope.$broadcast('AnnexaChangeAmountBudget', { amount: amount});
            }
            
           
            this.$onInit = function() {
         	   if(!vm.data){
         		   vm.data = {budgetItems: []};
         	   }
         	   if(!vm.data.budgets){
         		  vm.data.budgets = [];
         	   }else{
         		   vm.data.budgets.length = 0;
         	   }
			   if(!vm.data.projects){
         		  vm.data.projects = [];
         	   }else{
         		   vm.data.projects.length = 0;
         	   }
			   if(!vm.data.allProjects){
         		  vm.data.allProjects = [];
         	   }else{
         		   vm.data.allProjects.length = 0;
         	   }
     		  var accountInstance = ((TramFactory && TramFactory.userAccountInstances && TramFactory.userAccountInstances.length > 1)?undefined:TramFactory.userAccountInstances[0]);
              if(accountInstance){
				vm.data.accountInstance = accountInstance;
				var promises = [];
                promises.push(TramFactory.getBudgets(accountInstance.code, ((accountInstance.managementCenter && accountInstance.useManagementCenter)?accountInstance.managementCenter:undefined)));
                if(accountInstance.withProject){
					vm.showproject = true;
					promises.push(TramFactory.getProjects(accountInstance.code));
				}else{
					vm.showproject = false;
				}
				$rootScope.loadingDiv = true;
				$q.all(promises).then(function(data) {
					$rootScope.loadingDiv = false;
					if(data && data.length > 0){
						if(data.length > 1){
							var budgetsAux = JSOG.decode(data[0]);
							var projectsAux = JSOG.decode(data[1]);
							if(budgetsAux && budgetsAux.length > 0){ 
								if(projectsAux && projectsAux.length > 0){
									vm.data.allProjects = projectsAux;
									projectsAux = $linq(projectsAux).where(function(x){
										if(x && x.gastos && x.gastos.length > 0){
											var gastos = $linq(x.gastos).intersect(budgetsAux,"(x,y) => x.code == y.code").toArray(); 
											if(gastos && gastos.length > 0){
												return true;
											}else{
												return false;
											}
										}else{
											return false;
										}
									}).toArray();
									_.forEach(projectsAux, function(item){
		          				   		if(item.expedientes && item.expedientes.length > 1){
											for(var i= 0; i<item.expedientes.length; i++){
												var itemAux = angular.copy(item);
												itemAux.code = itemAux.code +'-'+item.expedientes[i].numExpe;
												itemAux.descripcion = '('+item.code +') '+itemAux.descripcion +' - ('+item.expedientes[i].numExpe+') '+item.expedientes[i].descExpe;
												itemAux.expediente = item.expedientes[i];
												vm.data.projects.push(itemAux);
											}
										}else{
											var itemAux = angular.copy(item);
											itemAux.descripcion = '('+itemAux.code +') '+itemAux.descripcion;												
											vm.data.projects.push(itemAux);	
										}
		          			   		});
								}
	          			   		_.forEach(budgetsAux, function(item){
    	      				   		vm.data.budgets.push(item);
        	  			   		});							
							}
						}else{
							var budgetsAux = JSOG.decode(data[0]);
          			   		_.forEach(budgetsAux, function(item){
          				   		vm.data.budgets.push(item);
          			   		});
						}
            		}
                	vm.data.budgetItems.length = 0;
                	$rootScope.$broadcast('AnnexaChangeAmountBudget', {amount: 0});
            	 }).catch(function (error) {
						$rootScope.loadingDiv = false;
						if(error && error.data && error.data.message && error.data.message != 'Error call to PDI'){
	            			   DialogsFactory.error(error.data.message);
	           		   }else{
	           			  DialogsFactory.error($filter('translate')('global.operationsAndInvoices.budgetsError'));
	           		   }
            		   vm.data.budgetItems.length = 0;
					   $rootScope.$broadcast('AnnexaChangeAmountBudget', {amount: 0});
                 });
              }else{
					vm.data.accountInstance = undefined;
			  }
            }
        }],
        bindings: {
        	data: '=?'
        }
    })